body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 22%;
  height: 70px;
  margin-right: 8px;
}

.upload-custom-card .ant-upload-list-picture-card-container    {
  float: left;
  width: 400px;
  height: 400px;
  margin: 0 8px 8px 0;
}
.upload-custom-card .ant-upload-list-picture-card .ant-upload-list-item  {
  float: left;
  width: 400px;
  height: 400px;
  margin: 0 8px 8px 0;
}

.upload-custom-card  .ant-upload.ant-upload-select-picture-card > .ant-upload{
  float: left;
  width: 390px;
  height: 390px;
  margin: 0 8px 8px 0;
}

.upload-custom-card  .ant-btn{
  margin-top: 200px;
}

.padding-20 {
  padding: 20px;
} 

.ribbon {
  background-color: rgba(201, 17, 17, 0.76);
  left: -3.5em;
  transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 30px;
  white-space: nowrap;
  width: 15em;
  z-index: 99999;
  pointer-events: none;
  opacity: 0.75;
}
.ribbon a {
  color: #fff;
  display: block;
  font-weight: 400;
  margin: 1px 0;
  padding: 10px 50px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 5px #444;
  pointer-events: none;
}

.align-right{
  text-align: right;
}

.margin-bottom-less-alert{
  margin-top: -30px;
}

.buttonGenerateToken {
  margin-top: 140px;
  position: fixed;
}

@media (max-width: 768.98px) {
  .buttonGenerateToken {
    margin-top: 10px;
    position: relative;
  }
  .margin-top-active-order {
    margin-top: 0px !important;
  }
  .margin-icon-remove-item{
    margin-left: -86%;
    display: block;;
  }
}

@media (max-width: 1888.98px) {
  .buttonGenerateToken {
    margin-top: 120px;
    position: fixed;
  }
}

.custom-select > .ant-select-selection {
  background-color: #1E8A9A;
  border: 1px solid #1E8A9A;
  color: #f8f8f8e3;
  font-weight: bold;
}

.margin-top-active-order {
  margin-top: -157px;
}

.ant-tag {
  margin-bottom: 8px;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.margin-icon-remove-item{
  margin-top: 27px;
}
.ant-layout-sider-trigger{
background: #1e8a9a 
}
.anticon-logout {
  color: #fff;
  width: 35px;
  
}
.collapsible .anticon{
  margin: 16px !important;
  margin-top: 6px !important
}

.ant-form-item{
  margin-bottom: 4px;
}

.alert-table .ant-form-item{
  margin-bottom: 24px;
}