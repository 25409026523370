.client {
    background-color: lightsalmon;
}

.application{
    background-color: lightgray;
}

.group{
    background-color: lightskyblue;
}

.profile{
    background-color: lightgoldenrodyellow;
}